.menu-1{
  display: flex;
  flex-direction: row;
  justify-content: space-evenly;
}

.menu-img1 img{
  width: 500px;
  height: 300px;
  border-radius: 20px;
}

.menu-des1 p{
  max-width: 707px;
  text-align: justify;
}

