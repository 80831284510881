.row{
    display: flex;
    flex-direction: row;
}

.imgg4{
    width: 584px;
}

.row34{
    display: flex;
    flex-direction: row;
}