.f1{
    display:flex;
    justify-content: center;
   
    flex-direction: column;
}

.f-all{
    display: flex;
    flex-direction: row;
    justify-content: space-evenly;
}

.f1 h5{
    max-width: 340px;
    text-align: justify;
    color: gray;
}

@media only screen and (max-width: 767px) {

.f-all{
  display: flex;
  flex-direction: column; 
  justify-content: center;
  align-items: center;
}

.f1 h5{
    max-width: 150px;
    text-align: justify;
    color: gray;

}

}
