.m-header{
    text-align: center;
}

.menu1{
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

.m-menu{
    display: flex;
    justify-content: space-evenly;
    flex-direction: row;
}

.para12{
    color: black;
    text-align: justify;
    font-size: 14px;
    padding-left: 20px;
    padding-right: 20px;
    padding-bottom: 10px;
}

.h33{
    color: black;
}
.menu-c{
    width: 300px;
    height: 390px;
    background-color:white;
    border-radius: 20px;

}

.imggg{
    width: 100%;
    height: 200px;
  
    border-radius: 20px;
    
    
}


.menuu-all{
    display: flex;
    flex-direction: row;
    justify-content: space-evenly;
}






.menu111{

   display: flex;
   flex-direction: row;
   justify-content: space-between;
   



}


.b-1{
    display: flex;
    justify-content: center;
    align-items: center;
    padding-top: 30px;
}

.b-1 button{
    
    border-radius: 30px;
     color: black;
     border: none;
     width: 180px;
     height: 40px;

}

.b-1 button:hover{
    background-color: antiquewhite;
}


.m-all{
    margin-top: -290px;
}

@media only screen and (max-width: 767px) {


.m-menu{
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
   
}

.menuu-all{
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    
   
    
}

.menu-c{
    padding-bottom: 20px;
}




}
    
