.points{
    display: flex;
    
    flex-direction: row;
    
}

.padd{
    padding-left: 30px;
}



.about{
   margin-top: -80px;
}